/* DonorPortfolioSection.css */

.donor-portfolio-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .empty-state-container {
    text-align: center;
    padding: 40px;
  }
  
  .mascot-image {
    max-width: 300px;
    margin-bottom: 24px;
  }
  
  .manage-donors-button {
    margin-top: 20px;
    background-color: #4caf50; /* Green color for the button */
    color: white;
  }
  
  .manage-donors-button:hover {
    background-color: #43a047; /* A shade darker for hover state */
  }
  
  .donors-grid {
    margin-top: 20px;
    width: 100%;
  }
  
  
  .donor-card-content {
    padding: 16px;
  }
  
  /* You can add transitions for interactive elements */
  button {
    transition: all 0.3s ease;
  }
  

  /* DonorPortfolioSection.css */

  
  
  .donor-card {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    margin: 10px;
    transition: box-shadow 0.3s ease-in-out;
  }
  
  .donor-card:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
  }
  
  .add-donors-button {
    margin-top: 20px;
  }
  
  /* Style for Dialog components */
  .dialog-content {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .dialog-actions {
    padding: 20px;
  }
  